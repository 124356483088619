@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Custom animations */
  @keyframes float {
    0%, 100% {
      transform: translateY(0) translateX(0);
    }
    25% {
      transform: translateY(-10px) translateX(10px);
    }
    50% {
      transform: translateY(0) translateX(20px);
    }
    75% {
      transform: translateY(10px) translateX(10px);
    }
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  .animate-blink {
    animation: blink 1s step-end infinite;
  }
}

/* Scroll behavior */
html {
  scroll-behavior: smooth;
}

/* For the policy pages content */
.prose h2 {
  @apply text-2xl font-bold text-gray-900 mb-4 mt-8;
}

.prose h3 {
  @apply text-xl font-bold text-gray-900 mb-3 mt-6;
}

.prose p {
  @apply text-gray-700 mb-4;
}

.prose ul {
  @apply list-disc pl-6 space-y-2 mb-4;
}

.prose li {
  @apply text-gray-700;
}

.prose a {
  @apply text-purple-600 hover:text-purple-800 transition-colors duration-300;
}

.blog-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.blog-item-0 {
  transition-delay: 0ms;
}

.blog-item-1 {
  transition-delay: 200ms;
}

.blog-item-2 {
  transition-delay: 400ms;
}

.blog-item.visible {
  opacity: 1;
  transform: translateY(0);
}